import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

export interface Menu {
  state?: string;  // Making state optional
  name: string;
  type: string;
  icon: string;
  img?: string;
  placeholder?: string;
  action?: () => void;
}


const SUPERADMINMENUITEMS = [
  { state: 'admin/dashboard', name: '', placeholder: "Dashboard", type: 'link', icon: '', img: 'assets/images/icons/menus/dashboard.png' },
  { state: 'admin/clinics', name: '', placeholder: "Clinics", type: 'link', icon: '', img: 'assets/images/icons/menus/clinic.png' },
  { state: 'admin/vets', name: '', placeholder: "Vets", type: 'link', icon: '', img: 'assets/images/icons/menus/users.png' },
  { state: 'admin/pets', name: '', placeholder: "Pets", type: 'link', icon: '', img: 'assets/images/icons/menus/pets.png' },
  { state: 'questions', name: '', placeholder: "Questions", type: 'link', icon: '', img: 'assets/images/icons/menus/question.png' }
];

const CLINICMENUITEMS = [
  { state: 'clinic/dashboard', name: '', placeholder: "Dashboard", type: 'link', icon: '', img: 'assets/images/icons/menus/dashboard.png' },
  // { state: 'clinic/patient-info', name: '', placeholder: "Reports", type: 'link', icon: '', img: 'assets/images/icons/menus/report.png' },
  { state: 'clinic/vets', name: '', placeholder: "Vets", type: 'link', icon: '', img: 'assets/images/icons/menus/users.png' },
  { state: 'clinic/pets', name: '', placeholder: "Pets", type: 'link', icon: '', img: 'assets/images/icons/menus/pets.png' },
  { state: 'questions', name: '', placeholder: "Questions", type: 'link', icon: '', img: 'assets/images/icons/menus/question.png' }
];

const VETMENUITEMS = [
  { state: 'vet/dashboard', name: '', placeholder: "Dashboard", type: 'link', icon: '', img: 'assets/images/icons/menus/dashboard.png' },
  // { state: 'vet/patient-info', name: '', placeholder: "Reports", type: 'link', icon: '', img: 'assets/images/icons/menus/report.png' },
  { state: 'vet/pets', name: '', placeholder: "Pets", type: 'link', icon: '', img: 'assets/images/icons/menus/pets.png' },
  { state: 'questions', name: '', placeholder: "Questions", type: 'link', icon: '', img: 'assets/images/icons/menus/question.png' }
];

@Injectable()
export class MenuItems {

  constructor(private authService: AuthenticationService) { }
  getMenuitem(): Menu[] {
    if (this.authService.getUserRole() == "SUPER_ADMIN") {
      return SUPERADMINMENUITEMS;
    } else if (this.authService.getUserRole() == "CLINIC") {
      return CLINICMENUITEMS;
    }
    return VETMENUITEMS;
  }
}
