import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

export const guestGuard = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const isAuthenticated = authService.isAuthenticated();

  if (!isAuthenticated) {
    return true;
  } else {
    router.navigate(['/clinic']); // Redirect to dashboard or other protected route if authenticated
    return false;
  }
};
