import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { NoLayoutComponent } from './layouts/no-layout/no-layout.component';
import { authGuard } from './services/auth.gaurd';
import { guestGuard } from './services/guest.gaurd'; // The new guest guard


export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canMatch: [authGuard], // Protect this route with authGuard
      },
      {
        path: 'clinic',
        loadChildren: () => import('./clinic-admin/clinic-admin.module').then(m => m.ClinicAdminModule),
        canMatch: [authGuard], // Protect this route with authGuard
      },
      {
        path: 'vet',
        loadChildren: () => import('./vet/vet.module').then(m => m.VetModule),
        canMatch: [authGuard], // Protect this route with authGuard
      }
    ]
  },
  {
    path: 'auth',
    component: NoLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canMatch: [guestGuard],
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/auth'
  }
];
