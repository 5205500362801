export class Configuration {
  BASE_URL = "";
  BASE_URL_AUTH = "";
  BASE_URL_AI = "";
  AUTH_KEY: string = "authDetail";

  constructor(public state: projectState) {
    if (this.state == "staging") {
      this.BASE_URL_AUTH = "https://dev.environment.com/";
      this.BASE_URL_AI = "https://dev.ai.environment.com/";
    } else if (this.state == "qa") {
      this.BASE_URL_AUTH = "https://qa.environment.com";
      this.BASE_URL_AI = "https://qa.ai.environment.com";
    } else if (this.state == "production") {
      this.BASE_URL_AUTH = "https://api.petchtech.com/";
      this.BASE_URL_AI = "https://ai.petchtech.com/";
    } else if (this.state == "preprod") {
      this.BASE_URL_AUTH = "https://preprod.environment.com";
      this.BASE_URL_AI = "https://preprod.ai.environment.com";
    } else if (this.state == "local") {
      this.BASE_URL_AUTH = "http://localhost:8001/";
      this.BASE_URL_AI = "http://localhost:8000/";
    }
  }

  ROLE_PERMISSIONS: any = {
    CLINIC: ["add-pet", "add-vet"]
  }
}
type projectState = "staging" | "production" | "qa" | "preprod" | "local";
export const configuration = new Configuration("production");
