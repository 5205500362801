<a *ngIf="authService.hasPermission('add-pet')" [routerLink]="['/clinic/add-pet']"
    class=" m-r-20 hidden-sm-up rounded-5 text-white  w-p-150" mat-raised-button color="primary">
  Add Pet
  <mat-icon>add_circle</mat-icon>
</a>

<div class="m-l-16 text-dark d-flex align-items-center pointer" [matMenuTriggerFor]="profile">
  <h6 class="f-w-400 m-0 mat-subtitle-1">
    <span *ngIf="authService.getUserName() && (authService.getUserRole() !== 'CLINIC')">{{authService.getUserName()}}</span>
    <span *ngIf="!authService.getUserName()">{{authService.getUserEmail()}}</span>
  </h6>
  <p>
    <span *ngIf="authService.getClinicName()">{{authService.getClinicName()}}</span>
  </p>
  <mat-icon>expand_more</mat-icon>
</div>

<!-- Button to trigger the menu, showing initials instead of profile pic -->
<!-- <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 p-0 d-flex jc-c align-items-center">
  <div class="initials">{{ getInitials('Olivia Smith') }}</div>
</button> -->

<mat-menu #profile="matMenu" class="mymegamenu">
  <a mat-menu-item [routerLink]="[profileLink]" [queryParams]="{id: authService.getUserId()}">
    <mat-icon>account_box</mat-icon> Profile </a>
  <a mat-menu-item (click)="onLogout()">
    <mat-icon>exit_to_app</mat-icon> Sign Out
  </a>
</mat-menu>




<!-- <a [routerLink]="profileLink" class=" m-r-20 hidden-sm-up"
  mat-raised-button color="warn">Dr. Olivia</a>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 p-0 d-flex jc-c align-items-center">
  <img src="assets/images/users/1.jpg" alt="user" class="profile-pic">
</button>

<div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
    <img src="assets/images/users/1.jpg" class="profile-pic">
    <div class="m-l-16">
        <h6 class="f-w-600 m-0 mat-subtitle-1">Mathew Anderson</h6><span
            class="d-block m-b-4">Designer</span>
    </div>
</div>

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings </button>
    <a mat-menu-item [routerLink]="profileLink">
        <mat-icon>account_box</mat-icon> Profile </a>
    <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications </button>
    <a mat-menu-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </a>
</mat-menu> -->
