import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

export interface Menu {
  state?: string;  // Making state optional
  name: string;
  type: string;
  icon: string;
  img?: string;
  placeholder?: string;
  action?: () => void;
}


const CLINICMENUITEMS = [
  { state: 'clinic/dashboard', name: '', placeholder: "Dashboard", type: 'link', icon: '', img: 'assets/images/icons/menus/dashboard.png' },
  { state: 'clinic/patient-info', name: '', placeholder: "Reports", type: 'link', icon: '', img: 'assets/images/icons/menus/report.png' },
  { state: 'clinic/vets', name: '', placeholder: "Vets", type: 'link', icon: '', img: 'assets/images/icons/menus/users.png' },
  { state: 'questions', name: '', placeholder: "Questions", type: 'link', icon: '', img: 'assets/images/icons/menus/question.png' }
  // Adding action for logout menu item
  // {
  //   action: () => null, // This will be triggered later in the component's template
  //   name: '',
  //   placeholder: "Logout",
  //   type: 'link',
  //   icon: '',
  //   img: 'assets/images/icons/menus/logout.png'
  // }
];

@Injectable()
export class MenuItems {

  constructor(private authService: AuthenticationService) { }
  getMenuitem(): Menu[] {
    if (this.authService.getUserRole() == "CLINIC") {
      return CLINICMENUITEMS;
    }
    return CLINICMENUITEMS;
  }
}
